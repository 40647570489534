import { Controller } from '@hotwired/stimulus';
import 'jquery-zoom';

declare global {
  interface JQuery {
    zoom(options: { url?: string; magnify?: number }): JQuery;
  }
}

// Connects to data-controller="record-image"
export default class extends Controller {
  static targets = [
    'image',
    'container',
    'notice',
    'noticeHeader',
    'thumbnail',
  ];

  declare imageTarget: HTMLImageElement;
  declare containerTarget: HTMLDivElement;
  declare hasNoticeTarget: boolean;
  declare noticeTarget: HTMLDivElement;
  declare hasNoticeHeaderTarget: boolean;
  declare noticeHeaderTarget: HTMLDivElement;
  declare thumbnailTargets: HTMLDivElement[];

  connect(): void {
    const url = this.containerTarget.dataset.full;
    this.#enableZoom(url);
  }

  show(event: Event): void {
    const element = event.currentTarget as HTMLDivElement;

    // remove active class from all thumbnails
    this.thumbnailTargets.forEach((thumbnail) => {
      thumbnail.classList.remove('page-album-thumbnail--active');
    });

    // add active class to clicked thumbnail
    element.classList.add('page-album-thumbnail--active');

    this.imageTarget.src = element.dataset.full ?? '';
    this.imageTarget.srcset = '';

    if (this.hasNoticeTarget) {
      this.noticeTarget.innerText = element.dataset.notice ?? '';
    }
    if (this.hasNoticeHeaderTarget) {
      this.noticeHeaderTarget.classList.toggle(
        'hidden',
        !element.dataset.notice
      );
    }
    this.#enableZoom(element.dataset.full);
  }

  #enableZoom(url?: string): void {
    if (!window.jQuery.fn.zoom) {
      throw new Error(`jquery-zoom not initialized on jQuery v${$.fn.jquery}`);
    }

    window.jQuery(this.containerTarget).zoom({ url });
  }
}
